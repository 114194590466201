import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    finishAssistantMessage,
    addRagProcess,
    addRagSource,
    updateThread,
    replaceMessage,
    addFilteredContentError,
    addProcessingTime,
} from "./chatSlice";
import {
    MessageEnum,
    messageTypeToEnum,
    PagedRequest,
    PaginatedResponse,
    RagProcessMessage,
    RagSourceMessage,
    System,
    Thread,
} from "../../types";

const openaiKey =
    "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";

interface SendMessageRequest {
    system: System;
    threadId?: string | null;
    message: string;
    messageId?: string;
    onThreadIdReceived?: (threadId: string) => void;
    options?: {
        version?: "Production" | "Beta" | "Alpha";
    };
}

interface MessageStreamResponse {
    message: string;
    processes: RagProcessMessage[];
    sources: RagSourceMessage[];
    done: boolean;
}

const getApiEndpoint = (options?: {
    version?: "Production" | "Beta" | "Alpha";
}) => {
    let endpoint = "/api/Chat/chatCompletion";
    if (options?.version?.toLowerCase() === "beta") {
        endpoint = "/api/Chat/chatCompletion-beta";
    } else if (options?.version?.toLowerCase() === "alpha") {
        endpoint = "/api/Chat/chatCompletion-alpha";
    }

    return endpoint;
};

const getParsedMessage = (message: string) => {
    try {
        return JSON.parse(message);
    } catch (error) {
        // Don't log this error, it's mostly noise
        // console.error("Could not JSON parse stream message", message, error);
        return null;
    }
};

export const chatApi = createApi({
    reducerPath: "chatApi",
    baseQuery: fetchBaseQuery({baseUrl: "/api"}),
    tagTypes: ["MessageStream", "Threads", "Thread"],
    endpoints: (builder) => ({
        sendMessage: builder.mutation<
            MessageStreamResponse,
            SendMessageRequest
        >({
            queryFn: () => ({
                data: {
                    message: "",
                    processes: [],
                    sources: [],
                    done: false,
                },
            }),
            async onQueryStarted(
                {
                    system,
                    threadId,
                    message,
                    messageId,
                    onThreadIdReceived,
                    options,
                },
                {dispatch, queryFulfilled}
            ) {
                if (!system) {
                    throw new Error("System configuration is not set.");
                }

                try {
                    const controller = new AbortController();

                    const endpoint = getApiEndpoint(options);

                    const response = await fetch(endpoint, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${openaiKey}`,
                        },
                        body: JSON.stringify({
                            system,
                            threadId,
                            sliceMessageId: messageId,
                            message: {
                                role: "user",
                                content: message,
                            },
                        }),
                        signal: controller.signal,
                    });

                    if (!response.ok) {
                        throw new Error(
                            `Error ${response.status}: ${response.statusText}`
                        );
                    }
                    if (!response.body) {
                        throw new Error(
                            "ReadableStream not yet supported in this browser."
                        );
                    }

                    const reader = response.body.getReader();
                    const decoder = new TextDecoder("utf-8");
                    let done = false;
                    let accumulatedText = "";
                    let partialChunk = "";

                    let delta = new Date();
                    while (!done) {
                        const {value, done: doneReading} = await reader.read();
                        done = doneReading;

                        // It seems that bytes in a chunk might be split over multiple reads
                        // So we need to decode the chunk and then split it into lines
                        const chunkValue = decoder.decode(value, {
                            stream: true,
                        });
                        const fullChunk = partialChunk + chunkValue;
                        const lines = fullChunk.split("\n");
                        partialChunk = lines.pop() || "";

                        for (const line of lines.filter(
                            (line) => line.trim() !== ""
                        )) {
                            const message = line.replace(/^data: /, "");

                            // Check if the message is the end of the stream
                            if (message === "[DONE]") {
                                done = true;
                                break;
                            }

                            const parsed = getParsedMessage(message);

                            // Handle the message type
                            switch (messageTypeToEnum(parsed)) {
                                case MessageEnum.ThreadMetaMessage: {
                                    dispatch(
                                        updateThread({
                                            id: parsed.threadId,
                                            name: parsed.name,
                                            messages: parsed.messages,
                                            datestamp: parsed.datestamp,
                                        })
                                    );

                                    onThreadIdReceived?.(parsed.threadId);
                                    break;
                                }
                                case MessageEnum.ChatCompletionMessage: {
                                    const content =
                                        parsed.choices[0]?.delta?.content;
                                    if (!content) {
                                        break;
                                    }

                                    accumulatedText += content;

                                    const timeSinceDelta =
                                        new Date().getTime() - delta.getTime();

                                    // Limit the amount of updates to the redux store
                                    if (timeSinceDelta > 100) {
                                        dispatch(
                                            replaceMessage(accumulatedText)
                                        );
                                        delta = new Date();
                                    }
                                    break;
                                }
                                case MessageEnum.RagProcessMessage: {
                                    dispatch(addRagProcess(parsed));
                                    break;
                                }
                                case MessageEnum.RagSourceMessage: {
                                    dispatch(addRagSource(parsed));
                                    break;
                                }
                                case MessageEnum.FilteredContentMessage: {
                                    dispatch(addFilteredContentError(parsed));
                                    break;
                                }
                                case MessageEnum.ThinkingCompleteMessage: {
                                    dispatch(addProcessingTime(parsed));
                                    break;
                                }
                            }
                        }
                    }

                    dispatch(finishAssistantMessage(accumulatedText));
                } catch (error) {
                    console.error("Error in streaming:", error);
                }

                await queryFulfilled;
            },
            invalidatesTags: ["Thread", "Threads"],
        }),
        likeMessage: builder.mutation<void, {messageId: string}>({
            query: ({messageId}) => ({
                url: `like`, // TODO - Update when endpoint has been implemented
                method: "PATCH",
                body: messageId,
            }),
        }),
        dislikeMessage: builder.mutation<void, {messageId: string}>({
            query: ({messageId}) => ({
                url: `dislike`, // TODO - Update when endpoint has been implemented
                method: "PATCH",
                body: messageId,
            }),
        }),
        getThreads: builder.query<PaginatedResponse<Thread>, PagedRequest>({
            query: ({pageNumber, pageSize}) => ({
                url: `/Thread/GetThreads?pageNumber=${pageNumber}&pageSize=${pageSize}`,
                method: "GET",
            }),
            providesTags: ["Threads"],
        }),
        getThread: builder.query<Thread | undefined, string>({
            query: (threadId) => ({
                url: `/Thread/GetCompleteThread?id=${threadId}`,
                method: "GET",
            }),
            providesTags: ["Thread"],
            transformResponse: (response: Thread) => {
                return {
                    ...response,
                    messages: response.messages.map((message) => ({
                        ...message,
                        ragProcessStart: message.ragProcessStart,
                        ragProcessEnd: message.ragProcessEnd,
                    })),
                };
            },
        }),
        deleteThread: builder.mutation<void, string>({
            query: (id) => ({
                url: `/Thread/DeleteThread?id=${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Threads", "Thread"],
        }),
        rateMessage: builder.mutation<
            void,
            {messageId: string; score: number; comment: string}
        >({
            query: ({messageId, score, comment}) => ({
                url: `/Thread/rateMessage`,
                method: "PATCH",
                body: {messageId, score, comment},
            }),
        }),
        /* customChatCompletion: builder.mutation<
        customChatCompletion: builder.mutation<
            any,
            {model: string; systemPrompt: string; query: string}
        >({
            query: ({model, systemPrompt, query}) => ({
                url: `CustomChatCompletion`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: {
                    model,
                    systemPrompt,
                    query,
                },
            }),
        }), */
    }),
});

export const {
    useSendMessageMutation,
    useGetThreadsQuery,
    useGetThreadQuery,
    useDeleteThreadMutation,
    /* useCustomChatCompletionMutation, */
} = chatApi;
